var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "green-border-box" },
      [
        _c("div", { staticClass: "top-part in-padd white-bg" }, [
          _c("div", { staticClass: "profile-photo" }, [
            _c("img", {
              staticClass: "profile-photo-img green-border-box",
              attrs: {
                src: _vm.$store.state.userData.image + "?fit=crop&w=150&h=150"
              },
              on: {
                error: function($event) {
                  return _vm.$common.changeDefaultImage($event, "brand")
                }
              }
            }),
            _vm._m(0)
          ]),
          _c("div", { staticClass: "afterImage" }, [
            _c("div", { staticClass: "vertical-center-transform" }, [
              _c("strong", [_vm._v(_vm._s(_vm.$store.state.userData.name))]),
              _c(
                "button",
                {
                  staticClass: "btn-positive btn-small",
                  attrs: {
                    "data-target": "#edit-user-popup",
                    "data-toggle": "modal"
                  }
                },
                [_vm._v("EDIT")]
              )
            ])
          ])
        ]),
        _c("div", [
          _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-4 part" }, [
            _c("label", [_vm._v("Login Email")]),
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.$store.state.userData.email) }
            })
          ]),
          _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-4 part" }, [
            _c("label", [_vm._v("Login Mobile Number")]),
            _c("span", [
              _vm._v(
                _vm._s(_vm._f("phone")(_vm.$store.state.userData.mobile_number))
              )
            ])
          ]),
          _vm._m(1),
          _c("div", { staticClass: "clearfix" })
        ]),
        _c("ChangePasswordPopup", {
          attrs: {
            popupId: "change-password-popup",
            userId: _vm.$store.state.userData.id
          }
        }),
        _c("EditUserPopup", {
          attrs: {
            popupId: "edit-user-popup",
            userId: _vm.$store.state.userData.id
          }
        }),
        _c("ChangePhotoPopup", {
          attrs: {
            popupId: "change-photo-popup",
            userId: _vm.$store.state.userData.id,
            oldImage: _vm.$store.state.userData.image
          },
          on: { changeImage: _vm.changeImage }
        })
      ],
      1
    ),
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "green-border-box in-padd white-bg" }, [
      _vm._m(2),
      _vm.invitations.length > 0
        ? _c("table", { staticClass: "table table-striped" }, [
            _vm._m(3),
            _c(
              "tbody",
              _vm._l(_vm.invitations, function(invitation, index) {
                return _c("tr", { key: invitation.id }, [
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(index + 1))
                  ]),
                  _c("td", [_vm._v(_vm._s(invitation.brand.name))]),
                  _c("td", [_vm._v(_vm._s(invitation.status))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        new Date(invitation.expire_date_time).toDateString()
                      )
                    )
                  ]),
                  _c("td", [
                    invitation.status == "PENDING"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-positive",
                            staticStyle: { margin: "0" },
                            attrs: {
                              "data-target": "#delete-invitation-popup",
                              "data-toggle": "modal"
                            },
                            on: {
                              click: function($event) {
                                return _vm.updateInvitation(
                                  invitation,
                                  "ACCEPTED"
                                )
                              }
                            }
                          },
                          [_vm._v("Accept ")]
                        )
                      : _vm._e(),
                    invitation.status == "PENDING"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-negative",
                            staticStyle: { margin: "0" },
                            attrs: {
                              "data-target": "#delete-invitation-popup",
                              "data-toggle": "modal"
                            },
                            on: {
                              click: function($event) {
                                return _vm.updateInvitation(
                                  invitation,
                                  "DECLINED"
                                )
                              }
                            }
                          },
                          [_vm._v("Decline ")]
                        )
                      : _vm._e()
                  ])
                ])
              }),
              0
            )
          ])
        : _vm._e(),
      !_vm.busy && (!_vm.invitations || _vm.invitations.length == 0)
        ? _c("h3", { staticClass: "text-center mt20" }, [
            _vm._v("\n        No Invitation received.\n      ")
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          "data-target": "#change-photo-popup",
          "data-toggle": "modal",
          href: "javascript:;"
        }
      },
      [_c("img", { attrs: { src: "/img/profile/editprofile.png" } })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-4 part" }, [
      _c("label", [_vm._v("Password")]),
      _c("span", [
        _c(
          "button",
          {
            staticClass: "btn-positive btn-small",
            attrs: {
              "data-target": "#change-password-popup",
              "data-toggle": "modal"
            }
          },
          [_vm._v("CHANGE")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("h2", { staticClass: "pull-left" }, [_vm._v("Brand Invitations")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("#")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Brand")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Status")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Expires On")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Action")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }