






































































import Vue from 'vue'
import Component from 'vue-class-component'
import ChangePasswordPopup from '@/components/common/ChangePasswordPopup.vue'
import EditUserPopup from '@/components/common/EditUserPopup.vue'
import ChangePhotoPopup from '@/components/common/ChangePhotoPopup.vue'
import { reporters } from 'mocha'

@Component({
  components: {
    ChangePasswordPopup,
    EditUserPopup,
    ChangePhotoPopup
  }
})
export default class Profile extends Vue {
  public invitations: any[] = []
  public totalCount = 0
  public busy: Boolean = false

  changeImage (image: string) {
    this.$store.dispatch('updateProfile', { id: this.$store.state.userData.id, user: { image: image } }).then(() => {
      this.$notify({
        text: 'Profile Picture Updated Successfully!',
        type: 'success'
      })
    })
  }

  getEmailInvitationsList (clear = true) {
    this.busy = true
    this.$store.dispatch('getEmailInvitationsList', { email: this.$store.state.userData.email }).then((response) => {
      if (clear) this.invitations = []
      this.invitations.push(...response.body.accessInvitations)
      this.busy = false
    }, (response) => {
      this.busy = false
    })
  }
  updateInvitation (invitation:any, type: string) {
    this.$store.dispatch('updateInvitationStatus', { userId: this.$store.state.userData.id, id: invitation.id, brandId: invitation.brandId, status: type }).then((response) => {
      this.getEmailInvitationsList()
      this.$notify({ type: 'success', text: 'Invitation has been ' + type })
      if (type === 'ACCEPTED') {
        window.location.reload()
      }
    }, (response) => {
      this.busy = false
    })
  }
  mounted () {
    this.getEmailInvitationsList()
  }
}
